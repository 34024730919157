import { gql } from 'apollo-angular';


export class LoginAdminMutation {
  mutation = gql`
    mutation loginAdmin($email: String!, $password: String!) {
      loginAdmin(email: $email, password: $password) {
        access_token
      }
    }
  `;

  constructor(public variables: { email: string, password: string }) { }
}

