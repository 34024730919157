import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { map } from "rxjs/operators";
import { environment } from "../../../environments/environment";
import { FileUpload } from "../entities/file-upload.entity";
import { FileUploadRequestQuery } from "../graphql/file-upload-request.query";

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {
  constructor(
    private apollo: Apollo,
    private httpClient: HttpClient,
  ) { }

  async uploadFile(file: File): Promise<FileUploadResponse> {
    const fileUploadRequest = await this.requestUploadURL(file).toPromise();

    const r = await this.httpClient.put(
      fileUploadRequest.signedUrl,
      file, {
      headers: {
        'x-amz-acl': 'public-read',
      }
    }
    ).toPromise();
    return {
      fullUrl: fileUploadRequest.fullUrl,
      filePath: fileUploadRequest.filePath,
    };
  }

  requestUploadURL(file: File) {
    return this.apollo.query(new FileUploadRequestQuery(file)).pipe(
      map(response => (response.data as any).admin_requestFileUploadURL as FileUploadRawResponse)
    );
  }

  async uploadPrivateFile(file: File): Promise<FileUpload> {
    const formData = new FormData();
    formData.append('file', file);
    const r = await this.httpClient.post(`${environment.backend.url}${environment.backend.rest_api_prefix}/file/private/upload`,
      formData
    ).toPromise();
    return r as FileUpload;
  }

  async downloadPrivateFile(id: string, mimeType: string) {
    const file: any = await this.httpClient.get(`${environment.backend.url}${environment.backend.rest_api_prefix}/file/private/${id}`, {
      responseType: 'arraybuffer',
    }).toPromise();
    const blob = new Blob([file], { type: mimeType });
    const url = window.URL.createObjectURL(blob);
    window.open(url);
  }

}

export interface FileUploadResponse {
  fullUrl: string;
  filePath: string;
}

export interface FileUploadRawResponse extends FileUploadResponse {
  signedUrl: string;
}
