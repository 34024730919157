<div class="otp-wrapper" *ngIf="step === 'login'">
  <div class="md-form otp">
    <code-input #codeInput [isCodeHidden]="false" inputType="text" [isCharsCode]="true" [codeLength]="6"
      (codeChanged)="onCodeChanged($event)" (codeCompleted)="onCodeCompleted($event)" id="form10">
    </code-input>
  </div>
  <div class="otp-description text-center">
    <p>Vul hierboven de tijdelijke code in die je ontvangen hebt op het e-mailadres {{ email?.value }}.</p>
    <p>Geen code ontvangen? Probeer het dan <a href="javascript:;" (click)="backToRequest()">nog een keer</a>.
    </p>
  </div>
</div>