import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RoutesRecognized } from '@angular/router';
import { AuthService } from 'src/app/shared/auth/auth.service';
import { UserQuery } from '../../../user/state/user.query';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  page = '';
  public isMenuCollapsed = true;
  user$ = this.userQuery.user$
  routeData: any;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public authService: AuthService,
    public userQuery: UserQuery
  ) {
    
    router.events.subscribe(event => {
      if (event instanceof RoutesRecognized) {
        let route = event.state.root;
        while (route.firstChild) {
          route = route.firstChild;
        }
        this.routeData = route.data;
        this.page = route?.data.type || '';
      }
      
    });
  }

  ngOnInit(): void {
  }

  isHomeView() {
    return this.router.url.match('^/$');
  }

  isManageView() {
    return this.router.url.match('^/manage$');
  }

  logout(type: 'user' | 'admin') {
    this.authService.logout(type);
  }
}
