import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { finalize } from "rxjs/operators";
import { LoaderService } from "../services/loader.service";

@Injectable()
export class loaderInterceptor implements HttpInterceptor {
    reqCount = 0;

    constructor(private loaderService: LoaderService) { }
    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.reqCount++;
        this.loaderService.show();

        return next.handle(req).pipe(
            finalize(() => {
                this.reqCount--;

                if (this.reqCount === 0) {
                    this.loaderService.hide();
                }
            })
        );
    }
}