import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UserLoadGuard } from './user/user-load.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./user/user.module').then((m) => m.UserModule),
    data: {
      type: 'user',
    },
    canActivateChild: [UserLoadGuard]
  },
  {
    path: 'manage',
    loadChildren: () =>
      import('./manage/manage.module').then((m) => m.ManageModule),
    data: {
      type: 'manage',
    },
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: false })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
