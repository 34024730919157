import { Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class QueryService<T> {
  unwrapMultiple(result: NestJSQueryMultipleResponse<T>, field: string): Array<T> {
    return result.data[field];//.edges.map(edge => edge.node);
  }
  unwrapSingle(result: NestJSQuerySingleResponse<T>, field: string): T {
    return result.data[field];
  }
}

export interface NestJSQueryMultipleResponse<T> {
  data: {
    [key: string]: T[]
  };

  //   [key: string]: {
  //     edges: {
  //       node: T;
  //     }[];
  //   };
  // };
}

export interface NestJSQuerySingleResponse<T> {
  data: {
    [key: string]: T
  };
}


