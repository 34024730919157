import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { Unsubscribable } from 'rxjs';
import { environment } from '../environments/environment';
import { LoaderService } from './shared/services/loader.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'dashboard';
  loading = true;
  sub: Unsubscribable[] = [];
  constructor(public loaderService: LoaderService, private gtmService: GoogleTagManagerService, private router: Router) {
    let timeout: any;
    this.sub.push(this.loaderService.isLoading.subscribe(isLoading => {
      clearTimeout(timeout);
      
      timeout = setTimeout(() => {
        this.loading = isLoading;
      }, 100);

    }));
    if (environment.tracking.google_tag_manager.enable_tracking) {
      this.router.events.subscribe(item => {
  
        if (item instanceof NavigationEnd) {
            const gtmTag = {
                event: 'page',
                pageName: item.url
            };
    
            this.gtmService.pushTag(gtmTag);
        }
      });
    }
  }

  ngOnInit(): void {
    
    if (environment.useFeedback) {
      const myWin = window as any;
      myWin.Userback = myWin.Userback || {};
      myWin.Userback.access_token = '30646|45578|2j0dUpYLJchmeVoskyXEK0hu8';
      let node = document.createElement('script');
      node.src = 'https://static.userback.io/widget/v1.js';
      node.type = 'text/javascript';
      node.async = true;
      // node.charset = 'utf-8';
      document.getElementsByTagName('head')[0].appendChild(node);
      var s = document.createElement('script');
      s.async = true;
      s.src = 'https://static.userback.io/widget/v1.js';
      (document.head || document.body).appendChild(s);
    }
  }

  ngOnDestroy(): void {
    this.sub.forEach(item => item.unsubscribe());
  }

  
}
