import { Component } from '@angular/core';

@Component({
  selector: 'app-logo-no-text',
  templateUrl: './logo-no-text.component.html',
  styleUrls: ['./logo-no-text.component.scss']
})
export class LogoNoTextComponent {
  constructor() {}
}
