import { gql } from 'apollo-angular';


export class LoginUserWithCodeMutation {
  mutation = gql`
    mutation loginUserWithCode($email: String!, $code: String!) {
      loginUserWithCode(email: $email, code: $code) {
        access_token
      }
    }
  `;

  constructor(public variables: { email: string, code: string }) { }
}

