import { Pipe, PipeTransform } from '@angular/core';
import { EventTypeEnum } from '../entities/ea-event.entity';

@Pipe({
  name: 'eventType'
})
export class EventTypePipe implements PipeTransform {

  transform(value?: EventTypeEnum, location?: boolean): string {
    
    if (!location) {
      switch (value) {
        case EventTypeEnum.GTW:
          return 'GoToWebinar';
        case EventTypeEnum.ONLINE:
          return 'Online';
        case EventTypeEnum.OFFLINE:
          return 'Offline';
        case EventTypeEnum.GTW_HYBRID:
          return 'Hybride (GTW)';
        case EventTypeEnum.WEBINAR_GEEK_HYBRID:
          return 'Hybride (Webinar Geek)';
        case EventTypeEnum.WEBINAR_GEEK:
          return 'Webinar Geek';
        default:
          return '';
      }
    } else if (location) {
      if (
        value ===  EventTypeEnum.GTW ||
        value ===  EventTypeEnum.WEBINAR_GEEK ||
        value === EventTypeEnum.ONLINE
      ) {
        return 'Online';
      } else if (value == EventTypeEnum.GTW_HYBRID || value == EventTypeEnum.WEBINAR_GEEK_HYBRID ){
        return 'Online & Offline';
      } else {
        return 'Offline';
      }
    } else {
      return '';
    }
  }

}
