<div class="ring">
  <svg style="margin-top: -1.9em; margin-left: -.2em" viewBox="610 0 400 400" width="100" title="rocket">
    <path
      d="M181.49,11.49H164.15v8.38H171V82.14h-6.89v8.68h20.49L181.69,12Zm.2,0V12l34.58,78.83h13L194.5,11.49Zm47.59,79.33h10.84V19.87h6.8V11.49h-20.5Zm83.06-22.67V62c0-30.44-22.86-31.23-30.94-31.23-17,0-26.31,12.51-26.31,30.94,0,23.45,9.17,31.13,26.31,31.13,22.27,0,25.23-11,25.23-11H270.76V41.65h27.69l-.1,15.76-25.91,5.82Zm70,8.48V33H365.36c0,15.57.1,28.88.1,43.66H342.31l.1-43.66H322.3v8.68h7V68.94l18.53,24H360l14.09-24,13.5,24H400l18.52-24V41.65h7V33h-20.2V76.63Z"
      fill="#1d1d1b"></path>
    <path
      d="M78.53,152.48,40,141.85l38.53-10.48Zm-43.4,38.23V97.42l53.73,8.71V84.87H0V97.42H14.61v93.29H0v13H88.86V181.85Zm81.67,0H106.47v13h29.08V132.4h35.72l-.15,71.3h30.11v-13H190.75V142.15c0-15.65-10.92-28.35-26.86-28.35-13.73,0-26,15.21-28.34,17.86l-20.08-19.92L99.38,127.68l17.42,17.56Zm184.55-21V160.6c0-45.61-34.25-46.8-46.35-46.8-25.54,0-39.42,18.75-39.42,46.35,0,35.13,13.73,46.65,39.42,46.65,33.36,0,37.78-16.54,37.78-16.54H239.05V130h41.48l-.15,23.62-38.82,8.71Zm26.92,21H317.79v13H347.9l-.15-69.08,19.78,4c6.64,1.48,20.37,4.87,20.37-10.18,0-8.42-6-14.47-14.32-14.47-8.12,0-13.13,3.1-19.19,7.68-4.13,3-12.84,8.41-12.84,8.41l-8-13.87-19,7.82,13.73,21.25Zm251-60.67h10.34v-13H559.5v48.42H519.06l.15-48.42H489.1v13h10.48v26.57l37.19,30,18.75-14.17-.59,40.89H511.09v16.09h22.14c12.4,0,34.1-7.38,37.94-37Z"
      fill="#1d1d1b"></path>
    <path d="M449.87,106a16.94,16.94,0,1,0,16.94-16.78A16.95,16.95,0,0,0,449.87,106" fill="#1d1d1b"></path>
    <path
      d="M406.48,174.57c-4.32,0-17.74,3.68-17.74,20,0,11.18,7.67,18.69,16.94,18.85,0,.16,51,0,51,0v16c10.38,0,19.65-2.4,19.65-17.26,0-17.41-12.3-19.33-29.4-19.33H406.48Z"
      fill="#1d1d1b"></path>
    <rect x="406.48" y="117.05" width="40.44" height="48.41" fill="#1d1d1b"></rect>
    <path d="M165.94,298.15H144.23l-4.59,12h-9.48L150,260.67h10.11L180,310.17h-9.47Zm-3.18-8.2-7.64-20-7.71,20Z"
          fill="#1d1d1b"></path>
    <path
      d="M182.78,292.35c0-11,8-18.67,18.73-18.67a18.39,18.39,0,0,1,13.79,5.66l-5.94,5.52a9.8,9.8,0,0,0-7.85-3.47c-6,0-10.18,4.46-10.18,11s4.1,11,10.32,11a9.71,9.71,0,0,0,8-3.82l5.94,5.52a18.06,18.06,0,0,1-14.08,6.08C190.7,311.09,182.78,303.38,182.78,292.35Z"
      fill="#1d1d1b"></path>
    <path
      d="M254,288.6v21.57H246.7l-.49-5.09c-2.83,4-7.85,6-12.52,6-6.36,0-12.44-3.89-12.44-11.24,0-8.13,7.42-11.32,14.35-11.32A38,38,0,0,1,245.79,290v-1.13c0-5.17-2.83-7.71-8.35-7.71a18.14,18.14,0,0,0-10.75,3.53l-3.25-6a25.59,25.59,0,0,1,15.49-4.95C248.69,273.68,254,278.63,254,288.6Zm-8.2,7.07a32.3,32.3,0,0,0-8.7-1.27c-4.17,0-7.43,1.42-7.43,5,0,3,2.41,4.67,6,4.67C240.62,304.09,245.79,300.84,245.79,295.67Z"
      fill="#1d1d1b"></path>
    <path
      d="M299,257.14v53h-7.36l-.42-5a13.88,13.88,0,0,1-11.81,5.94c-9.48,0-17.4-7.71-17.4-18.81s7.85-18.6,17.68-18.6c4.67,0,8.56,1.7,11,4.67V257.14Zm-7.57,35.21c0-6.57-4.24-11-10.47-11s-10.39,4.46-10.39,11,4.17,11,10.39,11S291.4,299.07,291.4,292.35Z"
      fill="#1d1d1b"></path>
    <path
      d="M325.91,303.81a13.12,13.12,0,0,0,9.34-3.75l5.3,5.44a20.36,20.36,0,0,1-14.42,5.59c-11,0-19.1-7.56-19.1-18.88,0-10.82,7.5-18.53,18.53-18.53,11.53,0,17.11,8.21,17.11,16.62a35.49,35.49,0,0,1-.49,5.09H315.59C316.65,300.7,320.4,303.81,325.91,303.81ZM315.59,289.1h18.88c-.21-4.6-3.32-8.06-9.05-8.06C320.19,281,316.65,284.15,315.59,289.1Z"
      fill="#1d1d1b"></path>
    <path
      d="M407.24,287.69v22.48H399V289.24c0-5-2.75-7.85-7.21-7.85s-8.7,3-8.7,8.13v20.65h-8.2V289.24c0-4.95-2.76-7.85-7.14-7.85s-8.7,2.9-8.7,8.13v20.65h-8.27V274.6h7.28l.5,4.81a13.6,13.6,0,0,1,22.7.78,14.41,14.41,0,0,1,12.37-6.51C401.65,273.68,407.24,279.34,407.24,287.69Z"
      fill="#1d1d1b"></path>
    <path d="M449.53,274.6l-20.08,49.72H421.6L427,310.24,411.7,274.6h9l10.25,25.11,9.55-25.11Z" fill="#1d1d1b"></path>
    <circle cx="816.44" cy="165.47" r="165.47" fill="#ffed00"></circle>
    <path
      d="M853.79,211.52,852,172.46l31.08-53.83a16.39,16.39,0,0,0,1.64-12.42L873.81,65.56,833.15,76.45a16.37,16.37,0,0,0-9.93,7.62l-31.09,53.84-34.72,18a16.42,16.42,0,0,0-6.65,6.36l-8.57,14.83a4.36,4.36,0,0,0,1.6,6l13.94,8-18.05,31.26a6.74,6.74,0,0,0,11.67,6.74l18-31.26,21,12.14,22.7,13.11-18,31.26a6.74,6.74,0,0,0,11.67,6.74l18-31.26,12.27,7.09a4.38,4.38,0,0,0,6-1.6l8.56-14.84A16.35,16.35,0,0,0,853.79,211.52Z"
      fill="#1d1d1b"></path>
    <path
      d="M788.79,224.33a6.73,6.73,0,0,0-9.2,2.46l-19.37,33.55a6.73,6.73,0,0,0,11.66,6.74l19.37-33.55A6.73,6.73,0,0,0,788.79,224.33Z"
      fill="#1d1d1b"></path>
    <polygon points="856.7 126.67 843.14 118.84 862.34 100.13 821.47 117.17 835.03 125 815.83 143.71 856.7 126.67"
             fill="#fff"></polygon>
  </svg>
  <span></span>
</div>
