import { Pipe, PipeTransform } from '@angular/core';
import { EventAttendanceApprovalStatusEnum } from '../entities/event-attendance.entity';

@Pipe({
  name: 'eventApprovalStatus'
})
export class EventApprovalStatusPipe implements PipeTransform {

  transform(value: EventAttendanceApprovalStatusEnum): string {
    switch (value) {
      case EventAttendanceApprovalStatusEnum.APPROVED:
        return 'Approved';
      case EventAttendanceApprovalStatusEnum.DISAPPROVED:
        return 'Disapproved';
      case EventAttendanceApprovalStatusEnum.NOT_AVAILABLE:
        return 'Not available';
      case EventAttendanceApprovalStatusEnum.OPEN:
        return 'Open';
      case EventAttendanceApprovalStatusEnum.NOT_ATTENDED:
        return 'Not attended';
      default:
        return '';
    }
  }

}
