import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";


@Injectable({
  providedIn: 'root'
})
export class ExportService {
  constructor(
    private httpClient: HttpClient,
  ) { }

  async exportData(filenamePrefix: string, identifier: string | undefined | null, endpoint: string) {
    const file: any = await this.httpClient.get(
      `${environment.backend.url}${environment.backend.rest_api_prefix}/export/${endpoint}`,
      {responseType: 'arraybuffer'})
      .toPromise();
    const blob = new Blob([file],{ type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = url;
    a.download = identifier ? `${filenamePrefix}_${identifier}.xlsx` : `${filenamePrefix}.xlsx`;
    a.click();
    window.URL.revokeObjectURL(url);
  }
}
