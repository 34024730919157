export const environment = {
  production: true,
  useFeedback: false,
  baseUrl: `https://ea-tool.gek.dev`,
  backend: {
    url: 'https://staging-api-4qisu.ondigitalocean.app',
    rest_api_prefix: '/api/v1',
  },
  gtw: {
    client_key: '761c9094-7bef-4fb4-bba5-ef28f282b3b8'
  },
  tracking: {
    google_tag_manager: {
      id: 'GTM-WMCJJDJ',
      enable_tracking: false      
    }
  },
  auth: {
    token_name: 'ea_staging_token',
    whitelisted_domains: ['staging-api-4qisu.ondigitalocean.app'],
    allowed_domains: ['staging-api-4qisu.ondigitalocean.app'],
    prefilled_user_email: 'user@gek.nl',
    prefilled_admin_email: 'hallo@gek.nl',
    // deepcode ignore NoHardcodedPasswords: Only staging
    prefilled_admin_password: 'gekgek',
    error_redirect_user_url: '/auth/login',
    error_redirect_admin_url: '/manage/auth/login',
    success_redirect_user_url: '/',
    return_url_key: 'return_url',
  },
};
