import { environment } from '../../../../environments/environment';

export function jwtOptionsFactory() {
  return {
    tokenGetter: async () => {
      return localStorage.getItem(environment.auth.token_name);
    },
    authScheme: 'Bearer ',
    // allowedDomains: ["localhost:3000"],
    // whitelistedDomains: ["localhost:3000"],
    allowedDomains: environment.auth.allowed_domains,
    whitelistedDomains: environment.auth.whitelisted_domains,
    // blacklistedRoutes: environment.auth.blacklisted_domains,
    // skipWhenExpired: true
  };
}
