import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { Router } from '@angular/router';
import { ApolloLink, InMemoryCache } from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';
import { APOLLO_OPTIONS } from 'apollo-angular';
import { HttpBatchLink } from 'apollo-angular/http';
import { onError } from 'apollo-link-error';
import { environment } from '../environments/environment';

const uri = `${environment.backend.url}/graphql`;

export function createApollo(httpLink: HttpBatchLink, router: Router) {
  const basic = setContext((operation, context) => ({
    headers: {
      Accept: 'charset=utf-8',
    },
  }));

  const errorLink: any = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
      graphQLErrors.forEach(({ message, locations, path, extensions }) => {
        if (!environment.production) {
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}, Extensions: ${JSON.stringify(
              extensions
            )}`
          );
        }
        if (extensions?.code === 'UNAUTHENTICATED') {
          localStorage.clear();
          // location.href = '/';
          router.navigateByUrl('/');
        }
      });

    if (networkError) console.log(`[Network error]: ${JSON.stringify(networkError)}`);
  });

  const auth = setContext((operation, context) => {
    const token = localStorage.getItem('access_token');

    if (token === null) {
      return {};
    } else {
      return {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
    }
  });

  const link = ApolloLink.from([
    // basic,
    auth,
    errorLink,
    httpLink.create({ uri, batchInterval: 120 }),
  ]);

  // const link = ApolloLink.from([
  //   errorLink,

  // ]);

  const cache = new InMemoryCache();

  return {
    link,
    cache,
  };
}

@NgModule({
  exports: [HttpClientModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpBatchLink, Router],
    },
  ],
})
export class GraphQLModule { }
