<app-navbar></app-navbar>
<ng-progress class="root-progress-bar" [thick]="true" [meteor]="false" [spinner]="true" color="#qFBE400"></ng-progress>
<div class="layout">
  <main class="main mt-md-10 mt-5">
    <div class="container">
      <app-spinner [ngClass]="{'show': (loading)}"></app-spinner>
      <!-- <ng-container *ngIf="">
      </ng-container> -->
        <router-outlet></router-outlet>
    </div>
  </main>
</div>
