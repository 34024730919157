import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Apollo } from 'apollo-angular';
import { map, tap } from 'rxjs/operators';
import { AdminProgramEnrollment } from 'src/app/manage/programs/entities/program-enrollment-entity.admin';
import { Confirmable } from '../../decorators/confirmable.decorator';
import { EventAttendanceRaw } from '../../entities/event-attendance.entity';
import { ProgramEnrollmentStatus } from '../../entities/program-enrollment.entity';
import { User } from '../../entities/user.entity';
import { DeleteProgramEnrollmentMutation } from '../../graphql/delete-program-enrollment.mutation';
import { ExportService } from '../../services/export.service';
import { NestJSQuerySingleResponse, QueryService } from '../../services/query.service';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent implements OnInit {
  @Input() progressStatus?: ProgramEnrollmentStatus; 
  @Input() program!: any | undefined;
  @Input() programEnrollmentId?: string; 
  @Input() text?: string;
  @Input() user?: User;
  @Input() eventAttendances: EventAttendanceRaw[] | undefined;
  @Output() onDeleted: EventEmitter<string> = new EventEmitter();
  expanded = false;
  page$ = this.route.root.firstChild?.data.pipe(map(({type}) => type));

  constructor(    
    private route: ActivatedRoute,
    private apollo: Apollo,
    private queryService: QueryService<AdminProgramEnrollment>,
    private exportService: ExportService
    ) {
    }
    
  ngOnInit() {
  }

  @Confirmable('Are you sure you want to remove this enrollment?')
  async deleteProgramEnrollment() {
    if (this.programEnrollmentId) {
      await this.apollo.mutate(new DeleteProgramEnrollmentMutation({
        input: {
          id: this.programEnrollmentId,
        }
      })).pipe(
        map((result, loading) => {
          return this.queryService.unwrapSingle(result as NestJSQuerySingleResponse<AdminProgramEnrollment>, 'deleteOneAdmin_ProgramEnrollment');
        }),
        tap(() => {
          this.onDeleted.emit(this.programEnrollmentId);
        })
      ).toPromise();
    }
  }

  async exportAttendances() {
      const endpoint = `program/${this.program?.id}/user/${this.user?.id}`
      this.exportService.exportData(`export_${this.program.name}`, `${this.user?.firstName} ${this.user?.lastName}`, endpoint);
  }

}
