import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { User } from '../../shared/entities/user.entity';
import { StoreMetaData } from '../../shared/state/metadata.interface';


export interface UserState {
  user?: User,
  metadata: {
    user?: StoreMetaData,
  }
}

export function createInitialState(): UserState {
  return {
    // user: null,
    metadata: {}
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'user' })
export class UserStore extends Store<UserState> {

  constructor() {
    super(createInitialState());
  }
}
