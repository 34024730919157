import { EventOccurrence } from './event-occurrence.entity';
import { ProgramToEventBase } from './program-to-event.entity';

export enum EventTypeEnum {
  GTW = 'GTW',
  ONLINE = 'ONLINE',
  OFFLINE = 'OFFLINE',
  GTW_HYBRID = 'GTW_HYBRID',
  WEBINAR_GEEK = 'WEBINAR_GEEK',
  WEBINAR_GEEK_HYBRID = 'WEBINAR_GEEK_HYBRID'
}
export enum EventTypeValueEnum {
  GTW = 'GoToWebinar',
  ONLINE = 'Online',
  OFFLINE = 'Offline',
  GTW_HYBRID = 'Hybride (GTW)',
  WEBINAR_GEEK = 'Webinar Geek',
  WEBINAR_GEEK_HYBRID = 'Hybride (Webinar Geek)'
}
export enum EventLabelEnum {
  LEARNING_ACTIVITY = "LEARNING_ACTIVITY",
  CAREER_EVENT = "CAREER_EVENT",
  MICRO_MBA = "MICRO_MBA",
  MASTERCLASS = "MASTERCLASS",
  EVENT = "EVENT",
}
export enum EventLabelValueEnum {
  LEARNING_ACTIVITY = "Activity",
  CAREER_EVENT = "Career event",
  MICRO_MBA = "Micro MBA",
  MASTERCLASS = "Masterclass",
  EVENT = "Event",
}
export interface EAEvent extends EAEventBase {
  id: string;
  eventOccurrences: EventOccurrence[]
}

export interface EAEventBase {
  id?: string;
  name: string;
  description: string;
  imageUrl?: string;
  type: EventTypeEnum;
  label: EventLabelEnum;
  active: boolean;
  url?: string;
  programToEAEvents: ProgramToEventBase[]
}
