import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { JwtModule, JWT_OPTIONS } from '@auth0/angular-jwt';
import { akitaConfig } from '@datorama/akita';
import { AkitaNgEffectsModule } from '@datorama/akita-ng-effects';
import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { APOLLO_FLAGS } from 'apollo-angular';
import { NgBootstrapFormValidationModule } from 'ng-bootstrap-form-validation';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { NgProgressModule } from 'ngx-progressbar';
import { NgProgressHttpModule } from 'ngx-progressbar/http';
import { NgProgressRouterModule } from 'ngx-progressbar/router';
import { ToastrModule } from 'ngx-toastr';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { GraphQLModule } from './graphql.module';
import { jwtOptionsFactory } from './shared/auth/jwt/jwt-options.factory';
import { loaderInterceptor } from './shared/interceptors/loader.interceptor';
import { LoaderService } from './shared/services/loader.service';
import { SharedModule } from './shared/shared.module';

akitaConfig({ resettable: true });

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(
      {
        positionClass: 'toast-bottom-right',
        preventDuplicates: true,
      }
    ),
    AppRoutingModule,
    NgbModule,
    CoreModule,
    SharedModule,
    NgBootstrapFormValidationModule.forRoot(),
    AkitaNgEffectsModule.forRoot([]),
    HttpClientModule,
    GraphQLModule,
    environment.production ? [] : AkitaNgDevtools.forRoot({}),
    JwtModule.forRoot({
      jwtOptionsProvider: {
        provide: JWT_OPTIONS,
        useFactory: jwtOptionsFactory,
      },
    }),
    NgxDropzoneModule,
    NgProgressModule,
    NgProgressHttpModule,
    NgProgressRouterModule,
    GoogleTagManagerModule.forRoot({
      id: environment.tracking.google_tag_manager.id,
    })
  ],
  providers: [
    {
      provide: APOLLO_FLAGS,
      useValue: {
        useInitialLoading: true,
      },
    },

    LoaderService,
    { provide: HTTP_INTERCEPTORS, useClass: loaderInterceptor, multi: true }

  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
