import { Pipe, PipeTransform } from '@angular/core';
import { EAEvent, EventTypeEnum } from '../entities/ea-event.entity';
import { EventOccurrence } from '../entities/event-occurrence.entity';

@Pipe({
  name: 'eventDate'
})
export class EventDatePipe implements PipeTransform {

  transform(event: EAEvent, type: 'past' | 'future' | 'specific' = 'future', eventOccurrenceId?: string): Date | undefined {
    const occurrences = event.eventOccurrences;

    // switch (event.type) {
    //   case EventTypeEnum.GTW_WEBINAR:
    //   case EventTypeEnum.GTW_ELA:
    //   case EventTypeEnum.ONLINE_WEBINAR:
    //   case EventTypeEnum.ONLINE_ELA:
    //     if (event.eventOccurrences) {
    //       occurrences = event.eventOccurrences;
    //     }
    //     break;
    //   case EventTypeEnum.OFFLINE_ELA:
    //   case EventTypeEnum.OFFLINE_EVENT:
    //     if (event.offlineEventOccurrences) {
    //       occurrences = event.offlineEventOccurrences;
    //     }
    //     break;
    //   default:
    //     return undefined;
    // }

    if (type === 'past') {
      return occurrences && occurrences.length > 0 ? occurrences.filter(o => +o.fromDate < +new Date()).sort((a, b) => +a.fromDate - +b.fromDate)[0].fromDate : undefined;
    } else if (type === 'future') {
      return occurrences && occurrences.length > 0 ? occurrences.filter(o => +o.fromDate > +new Date()).sort((a, b) => +b.fromDate - +a.fromDate)[0].fromDate : undefined;
    } else if (type === 'specific' && eventOccurrenceId) {
      return occurrences && occurrences.length > 0 ? occurrences.find(o => o.id === eventOccurrenceId)?.fromDate : undefined;
    }
    return undefined;
  }

}
