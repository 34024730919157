import { gql } from 'apollo-angular';


export class RequestUserTokenMutation {
  mutation = gql`
    mutation requestUserToken($email: String!,$referrer: String!) {
      requestUserToken(email: $email, referrer: $referrer) {
        success,
        isNewUser
      }
    }
  `;

  constructor(public variables: { email: string,  referrer: string }) { }
}

