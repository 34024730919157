import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Actions } from '@datorama/akita-ng-effects';
import { Observable } from 'rxjs';
import { bootstrapUserAction } from './state/user.actions';



@Injectable({
  providedIn: 'root'
})
export class UserLoadGuard implements CanActivate, CanActivateChild {
  constructor(
    private actions$: Actions
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    this.checkLoadingProfile();
    return true;
  }


  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    this.checkLoadingProfile();
    return true;
  }

  private checkLoadingProfile() {
    setTimeout(() => {
      this.actions$.dispatch(bootstrapUserAction());
    }, 0);
  }
}
