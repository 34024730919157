<ng-container *ngIf="progressStatus; else noProgressTmpl">
  <ng-container
    *ngTemplateOutlet="progress;context:{realAttended: progressStatus.realAttendedEvents, attended: progressStatus.attendedEvents, bg: '1', programTotal: (progressStatus.programTotal + progressStatus.programRequirements), programNumber: progressStatus.programNrOfEvents, type: 'events'}">
  </ng-container>
  <ng-container
    *ngTemplateOutlet="progress;context:{realAttended: progressStatus.realAttendedCareerEvents, attended: progressStatus.attendedCareerEvents, bg: '2', programTotal: (progressStatus.programTotal + progressStatus.programRequirements), programNumber: progressStatus.programnrOfCareerEvents, type: 'career events'}">
  </ng-container>
  <ng-container
    *ngTemplateOutlet="progress;context:{realAttended: progressStatus.realAttendedCareerEvents, attended: progressStatus.attendedCareerEvents, bg: '2', programTotal: (progressStatus.programTotal + progressStatus.programRequirements), programNumber: progressStatus.programnrOfCareerEvents, type: 'career events'}">
  </ng-container>
  <ng-container
    *ngTemplateOutlet="progress;context:{realAttended: progressStatus.realAttendedLearningActivities, attended: progressStatus.attendedLearningActivities, bg: '3', programTotal: (progressStatus.programTotal + progressStatus.programRequirements), programNumber: progressStatus.programnrOfLearningActivities, type: 'learning activities'}">
  </ng-container>
  <ng-container
    *ngTemplateOutlet="progress;context:{realAttended: progressStatus.realAttendedMasterclasses, attended: progressStatus.attendedMasterclasses, bg: '4', programTotal: (progressStatus.programTotal + progressStatus.programRequirements), programNumber: progressStatus.programnrOfMasterclasses, type: 'masterclasses'}">
  </ng-container>
  <ng-container
    *ngTemplateOutlet="progress;context:{realAttended: progressStatus.realAttendedMicroMBAs, attended: progressStatus.attendedMicroMBAs, bg: '5', programTotal: (progressStatus.programTotal + progressStatus.programRequirements), programNumber: progressStatus.programnrOfMicroMBAs, type: 'micro MBA\'s'}">
  </ng-container>
  <ng-container
    *ngTemplateOutlet="progress;context:{realAttended: progressStatus.programEnrollmentDeliverablesApproved, attended: progressStatus.programEnrollmentDeliverablesApproved, bg: '6', programTotal: (progressStatus.programTotal + progressStatus.programRequirements), programNumber:progressStatus.programRequirements, type: 'approved requirements'}">
  </ng-container>


  <div class="small" *ngIf="expanded">

    <ng-container
      *ngTemplateOutlet="progressText;context:{realAttended: progressStatus.realAttendedEvents, attended: progressStatus.attendedEvents, bg: '1', programTotal: (progressStatus.programTotal + progressStatus.programRequirements), programNumber: progressStatus.programNrOfEvents, type: 'events'}">
    </ng-container>
    <ng-container
      *ngTemplateOutlet="progressText;context:{realAttended: progressStatus.realAttendedCareerEvents, attended: progressStatus.attendedCareerEvents, bg: '2', programTotal: (progressStatus.programTotal + progressStatus.programRequirements), programNumber: progressStatus.programnrOfCareerEvents, type: 'career events'}">
    </ng-container>
    <ng-container
      *ngTemplateOutlet="progressText;context:{realAttended: progressStatus.realAttendedCareerEvents, attended: progressStatus.attendedCareerEvents, bg: '2', programTotal: (progressStatus.programTotal + progressStatus.programRequirements), programNumber: progressStatus.programnrOfCareerEvents, type: 'career events'}">
    </ng-container>
    <ng-container
      *ngTemplateOutlet="progressText;context:{realAttended: progressStatus.realAttendedLearningActivities, attended: progressStatus.attendedLearningActivities, bg: '3', programTotal: (progressStatus.programTotal + progressStatus.programRequirements), programNumber: progressStatus.programnrOfLearningActivities, type: 'learning activities'}">
    </ng-container>
    <ng-container
      *ngTemplateOutlet="progressText;context:{realAttended: progressStatus.realAttendedMasterclasses, attended: progressStatus.attendedMasterclasses, bg: '4', programTotal: (progressStatus.programTotal + progressStatus.programRequirements), programNumber: progressStatus.programnrOfMasterclasses, type: 'masterclasses'}">
    </ng-container>
    <ng-container
      *ngTemplateOutlet="progressText;context:{realAttended: progressStatus.realAttendedMicroMBAs, attended: progressStatus.attendedMicroMBAs, bg: '5', programTotal: (progressStatus.programTotal + progressStatus.programRequirements), programNumber: progressStatus.programnrOfMicroMBAs, type: 'micro MBA\'s'}">
    </ng-container>
    <ng-container
      *ngTemplateOutlet="progressText;context:{realAttended: progressStatus.programEnrollmentDeliverablesApproved, attended: progressStatus.programEnrollmentDeliverablesApproved, bg: '6', programTotal: (progressStatus.programTotal + progressStatus.programRequirements), programNumber:progressStatus.programRequirements, type: 'approved requirements'}">
    </ng-container>

    <ng-container *ngIf="progressStatus.requirementDetails && progressStatus.requirementDetails.length > 0">
      <br>
      <h5>Program deliverable details:</h5>
      <div class="row" *ngFor="let requirement of progressStatus.requirementDetails">
        <div class="col-md-8">
          {{requirement.requirement}}
        </div>
        <div class="col-md-4 text-right">
          {{requirement.status}}
        </div>
      </div>
    </ng-container>
  </div>

  <a href="javascript:;" class="small" (click)="expanded = !expanded">
    <ng-container *ngIf="!expanded; else closeTmpl">Expand progress details</ng-container>
  </a>
  <a *ngIf="user" (click)="deleteProgramEnrollment()" href="javascript:;" class="small" >
    <span class="ml-4">Delete program enrollment</span>
  </a>

  <a *ngIf="(page$ | async) === 'manage'" (click)="exportAttendances()" href="javascript:;" class="small" >
    <span class="ml-2">Export attendances</span>
  </a>
</ng-container>
<ng-template #noProgressTmpl>
  <small>There is no progress registered (yet) for this programme.</small>
</ng-template>
<ng-template #closeTmpl>
  Collapse details
</ng-template>

<ng-template #progress let-realAttended="realAttended" let-attended="attended" let-bg="bg"
  let-programTotal="programTotal" let-programNumber="programNumber" let-type="type">
  <div class="progress d-inline-block bg-progress-{{bg}}" *ngIf="programNumber > 0"
    [ngbTooltip]="attended + ' out of ' + programNumber + ' ' + type"
    [style.width.%]="(programNumber / programTotal) * 100">
    <div class="progress-bar bg-progress-bar-{{bg}}" role="progressbar" [attr.aria-valuenow]="attended"
      aria-valuemin="0" [attr.aria-valuemax]="programNumber" style="height:100%"
      [style.width.%]="(attended / programNumber) * 100">
      {{
      attended }}<ng-container *ngIf="attended !== realAttended">, real: {{
        realAttended }}</ng-container>
    </div>
  </div>
</ng-template>

<ng-template #progressText let-realAttended="realAttended" let-attended="attended" let-bg="bg"
  let-programTotal="programTotal" let-programNumber="programNumber" let-type="type">
  <ng-container *ngIf="programNumber > 0">
    Attended {{ attended }}<ng-container *ngIf="attended !== realAttended">, real: {{
        realAttended }}</ng-container> out of {{programNumber}} {{type}}<br>
  </ng-container>
</ng-template>