import { EventOccurrence } from './event-occurrence.entity';
import { User } from "./user.entity";

export interface EventAttendanceRaw {
  id: string;
  eventOccurrenceId: string;
  user?: User;
  userId?: string;
  approvalStatus: EventAttendanceApprovalStatusEnum;
  eventAttendanceType: EventAttendanceTypeEnum;
  viewType: ViewTypeEnum;
  redirectCode?: string;
  webinarGeekAssessmentResult?: boolean;
}

export interface EventAttendance extends EventAttendanceRaw {
  eventOccurrence: EventOccurrence;
  attendanceDate: Date;
}

export enum EventAttendanceApprovalStatusEnum {
  APPROVED = 'APPROVED',
  DISAPPROVED = 'DISAPPROVED',
  NOT_AVAILABLE = 'NOT_AVAILABLE',
  OPEN = 'OPEN',
  NOT_ATTENDED = "NOT_ATTENDED",
}

export enum EventAttendanceTypeEnum {
  ONLINE = 'ONLINE',
  OFFLINE = 'OFFLINE'
}

export enum ViewTypeEnum {
  LIVE = 'LIVE',
  ONDEMAND = 'ONDEMAND',
}

