import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { NgbProgressbarModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { CodeInputModule } from 'angular-code-input';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { LogoNoTextComponent } from './components/logo-no-text/logo-no-text.component';
import { LogoSpinnerComponent } from './components/logo-spinner/logo-spinner.component';
import { OtpInputComponent } from './components/otp-input/otp-input.component';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { UploadInputComponent } from './components/upload-input/upload-input.component';
import { EventApprovalStatusPipe } from './pipes/event-approval-status.pipe';
import { EventDatePipe } from './pipes/event-date.pipe';
import { EventTypePipe } from './pipes/event-type.pipe';
import { SplitPipe } from './pipes/split.pipe';



@NgModule({
  declarations: [
    EventTypePipe,
    EventDatePipe,
    SplitPipe,
    OtpInputComponent,
    UploadInputComponent,
    ProgressBarComponent,
    EventApprovalStatusPipe,
    LogoNoTextComponent,
    LogoSpinnerComponent,
    SpinnerComponent
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    CodeInputModule,
    NgbProgressbarModule,
    NgbTooltipModule,
    NgxDropzoneModule,
  ],
  exports: [
    EventTypePipe,
    EventDatePipe,
    SplitPipe,
    EventApprovalStatusPipe,
    UploadInputComponent,
    OtpInputComponent,
    ProgressBarComponent,
    LogoNoTextComponent,
    LogoSpinnerComponent,
    SpinnerComponent
  ]
})
export class SharedModule { }
