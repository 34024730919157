import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { CodeInputComponent } from 'angular-code-input';

@Component({
  selector: 'app-otp-input',
  templateUrl: './otp-input.component.html',
  styleUrls: ['./otp-input.component.scss']
})
export class OtpInputComponent implements OnInit {
  @ViewChild('codeInput') codeInput!: CodeInputComponent;
  @Input() step: 'request' | 'login' | undefined;
  @Input() form: FormGroup | undefined;
  @Input() email!: AbstractControl | null;
  @Input() focus: 'email' | 'otp' | undefined;
  @Input() otpCodeValue: string | null = null;
  @Output() otpCode = new EventEmitter<string | null>();
  @Output() cancelRequest = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit(): void {
  }

  backToRequest(): void {
    this.cancelRequest.emit(true);
    this.otpCode.emit(null);
  }

  onCodeChanged(code: string): void {
    if (code.length < 6) {
      this.otpCode.emit(null);
      // this.otpCode = null;
    } else {
      this.otpCode.emit(code);
      // this.otpCode = code;
    }
  }

  onCodeCompleted(code: string): void {
    this.form!.markAsTouched();
    this.otpCode.emit(code);
    // this.otpCode = code;
  }

}
