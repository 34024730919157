import { gql } from "apollo-angular";


export class FileUploadRequestQuery {
  query = gql`
  query admin_requestFileUploadURL($fileUpload:Admin_FileUploadInput!) {
  admin_requestFileUploadURL(fileUpload:$fileUpload) {
    signedUrl,
    filePath,
    fullUrl
  }
}
  `;

  variables: {
    fileUpload: {
      fileName: string;
      fileType: string;
    }
  };

  constructor(file: File) {
    this.variables = {
      fileUpload: {
        fileName: file.name,
        fileType: file.type,
      },
    };
  }

}
