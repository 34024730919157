<nav class="navbar navbar-expand-lg navbar-light bg-white fixed-top" *ngIf="routeData?.showNav !== false">
  <button class="navbar-toggler" type="button" (click)="isMenuCollapsed = !isMenuCollapsed"
    [attr.aria-expanded]="!isMenuCollapsed" data-toggle="collapse" data-target="#navbarTogglerDemo03"
    aria-controls="navbarTogglerDemo03" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <a *ngIf="page === 'user'" class="navbar-brand" routerLink="">
    <img src="../../../../assets/images/logo1.svg" alt="" width="150" />
  </a>
  <a *ngIf="page === 'manage'" class="navbar-brand" [routerLink]="['manage']">
    <img src="../../../../assets/images/logo1.svg" alt="" width="150" />
  </a>
  <ng-container *ngIf="authService.authenticated">

    <div class="collapse navbar-collapse" id="navbarTogglerDemo03" [ngbCollapse]="isMenuCollapsed">
      <ul class="navbar-nav mr-auto mt-2 mt-lg-0" *ngIf="page === 'user'">
        <li class="nav-item" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact: true}">
          <a class="nav-link" [routerLink]="['']">Dashboard</a>
        </li>
        <!-- 
          <li class="nav-item" ngbDropdown display="dynamic">
          <a class="nav-link dropdown-toggle" id="report-dropdown" ngbDropdownToggle role="button">
            Events
          </a>
          <div ngbDropdownMenu aria-labelledby="report-dropdown" class="dropdown-menu">
            <a ngbDropdownItem role="button" [routerLink]="['/events']">Overview</a>
            <a ngbDropdownItem role="button" [routerLink]="['/events/library']">Library</a>    
          </div>
        </li> -->
        <li class="nav-item" [routerLinkActive]="['active']">
          <a class="nav-link" [routerLink]="['events']">Events</a>
        </li>
        <li class="nav-item" [routerLinkActive]="['active']">
          <a class="nav-link" [routerLink]="['programs']">Programmes</a>
        </li>
      </ul>
      <ul class="navbar-nav mr-auto mt-2 mt-lg-0" *ngIf="page === 'manage'">
        <li class="nav-item" ngbDropdown display="dynamic">
          <a class="nav-link dropdown-toggle" id="report-dropdown" ngbDropdownToggle role="button">
            Users
          </a>
          <div ngbDropdownMenu aria-labelledby="report-dropdown" class="dropdown-menu">
            <a ngbDropdownItem role="button" [routerLink]="['manage/users']">Dashboard</a>
            <a ngbDropdownItem role="button" [routerLink]="['manage/users/all']">All users</a>
          </div>
        </li>
        <li class="nav-item" [routerLinkActive]="['active']">
          <a class="nav-link" [routerLink]="['manage/events']">Events</a>
        </li>
        <li class="nav-item" ngbDropdown display="dynamic">
          <a class="nav-link dropdown-toggle" id="report-dropdown" ngbDropdownToggle role="button">
            Programmes
          </a>
          <div ngbDropdownMenu aria-labelledby="report-dropdown" class="dropdown-menu">
            <a ngbDropdownItem role="button" [routerLink]="['manage/programs']">All Programmes</a>
            <a ngbDropdownItem role="button" [routerLink]="['manage/programs/deliverables']">All Deliverables</a>
            <a ngbDropdownItem role="button" [routerLink]="['manage/programs/enrollments']">All Enrollments</a>
          </div>
        </li>
        <li class="nav-item" [routerLinkActive]="['active']">
          <a class="nav-link" [routerLink]="['manage/communication']">Communication</a>
        </li>
        <li class="nav-item" ngbDropdown display="dynamic">
          <a class="nav-link dropdown-toggle" id="report-dropdown" ngbDropdownToggle role="button">
            Report
          </a>
          <div ngbDropdownMenu aria-labelledby="report-dropdown" class="dropdown-menu">
            <a ngbDropdownItem role="button" [routerLink]="['manage/report/events']">Events</a>
            <a ngbDropdownItem role="button" [routerLink]="['manage/report/program-enrollments']">Program enrollments</a>
          </div>
        </li>
      </ul>
      <ul class="nav navbar-nav navbar-right">
        <li *ngIf="page === 'user'" class="nav-item" ngbDropdown display="dynamic" placement="bottom-right">
          <a class="nav-link dropdown-toggle" id="userDropdown" ngbDropdownToggle role="button">
            <p *ngIf="(user$ | async); let user" class="user">
              <i class="far fa-user mr-2"></i> {{user.firstName}} {{user.lastName}}
            </p>
          </a>
          <div ngbDropdownMenu aria-labelledby="userDropdown" class="dropdown-menu">
            <a ngbDropdownItem role="button" [routerLink]="['/user/profile']">Profile</a>
            <a ngbDropdownItem role="button" href="mailto:academy@newenergycoalition.org">Email Us</a>
            <a ngbDropdownItem role="button" (click)="logout('user')">Logout</a>
          </div>
        </li>
        <li *ngIf="page === 'manage'" class="nav-item" ngbDropdown display="dynamic" placement="bottom-right">
          <a class="nav-link dropdown-toggle" id="manageUserDropdown" ngbDropdownToggle role="button">
            <p class="user">
              <i class="far fa-user mr-2"></i>
              Logged in as Manager
            </p>
          </a>
          <div ngbDropdownMenu aria-labelledby="manageUserDropdown" class="dropdown-menu">
            <a ngbDropdownItem role="button" [routerLink]="['manage/gtw']">GoToWebinar</a>
            <a ngbDropdownItem role="button" (click)="logout('admin')">Logout</a>
          </div>
        </li>
      </ul>
    </div>
  </ng-container>
</nav>