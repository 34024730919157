import { gql } from "apollo-angular";

export class DeleteProgramEnrollmentMutation {
  mutation = gql`

    mutation deleteOneAdmin_ProgramEnrollment($input: DeleteOneAdmin_ProgramEnrollmentInput!) {
      deleteOneAdmin_ProgramEnrollment(input: $input) {
        id
      }
    }
  `;

  constructor(public variables: {
    input: DeleteOneAdmin_ProgramEnrollmentInput
  }) { }
}

export class DeleteOneAdmin_ProgramEnrollmentInput {
  id!: string;
}