import { ActivatedRoute } from '@angular/router';
import { createAction, props } from '@datorama/akita-ng-effects';
import { User } from '../../shared/entities/user.entity';

export const bootstrapUserAction = createAction('[User] Bootstrap User');

export const loadUserProfileAction = createAction(
  '[User] Load User Profile',
);
export const loadUserProfileActionSuccess = createAction(
  '[User] Load User Profile Success',
  props<{ user: User; url?: string; route?: ActivatedRoute; }>()
);
export const loadUserProfileActionError = createAction(
  '[User] Load User Profile Error',
  props<{ error: any }>()
);


export const updateUserProfileAction = createAction(
  '[User] Update User Profile',
  props<{ user: any; urlParts?: string[]; route?: ActivatedRoute; queryParams?: any }>()
);
export const updateUserProfileActionSuccess = createAction(
  '[User] Update User Profile Success',
  props<{ user: any; urlParts?: string[]; route?: ActivatedRoute; queryParams?: any }>()
);
export const updateUserProfileActionError = createAction(
  '[User] Update User Profile Error',
  props<{ error: any }>()
);

// export const updateUserRequestAction = createAction(
//   '[User] Update User Request',
//   props<{ firstName: string; lastName: string; occupation?: string; occupationLocation?: string; url?: string; route?: ActivatedRoute; }>()
// );

// export const updateUserSuccessAction = createAction(
//   '[User] Update User Success',
//   props<{ result: { firstName?: string, lastName?: string; occupation?: string; occupationLocation?: string; }; url?: string; route?: ActivatedRoute; }>()
// );

// export const updateUserErrorAction = createAction(
//   '[User] Update User Error',
//   props<{ error: any }>()
// );
