import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Actions } from '@datorama/akita-ng-effects';
import { Apollo } from 'apollo-angular';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { GenericResponse } from '../entities/generic-response.entity';
import { NestJSQuerySingleResponse, QueryService } from '../services/query.service';
import { LoginAdminMutation } from './graphql/login-admin.mutation';
import { LoginUserWithCodeMutation } from './graphql/login-user-with-code.mutation';
import { RequestUserTokenMutation } from './graphql/request-user-token.mutation';
import { userLoggedInAction, userLoggedOutAction } from './state/user-auth-actions';
import { resetStores } from "@datorama/akita";

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  authSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  decodedToken: any;

  constructor(
    private router: Router,
    private jwtHelperService: JwtHelperService,
    private apollo: Apollo,
    private queryService: QueryService<GenericResponse>,
    private actions$: Actions
  ) {
    const token = this.authenticated;


    if (!!token) {
      this.decodedToken = this.jwtHelperService.decodeToken(token);
      if (!this.jwtHelperService.isTokenExpired(token)) {
        this.authSubject.next(!!token);
      } else {
        this.logout(this.decodedToken.type);
      }
    }

  }

  async request(email: string, referrer: string): Promise<GenericResponse> {
    try {
      const result = await this.apollo.mutate(new RequestUserTokenMutation({ email, referrer })).pipe(
        map((res) => this.queryService.unwrapSingle(res as NestJSQuerySingleResponse<GenericResponse>, 'requestUserToken'))
      ).toPromise();
      if (result) {
        return {
          success: true,
          isNewUser: result.isNewUser
        };
      }
    } catch (e: any) {
      console.log(e?.message);
    }
    return {
      success: false,
    };
  }

  async login(loginData: {
    email: string;
    code: string;
  }): Promise<any> {
    try {
      const { email, code } = loginData;
      const result = await this.apollo.mutate<{ loginUserWithCode: { access_token: string } }>(new LoginUserWithCodeMutation({ email, code })).pipe(
        map((res) => {
          if (res.data) {
            this.saveToken(res.data.loginUserWithCode.access_token);
            this.decodedToken = this.jwtHelperService.decodeToken(res.data?.loginUserWithCode.access_token);
            this.actions$.dispatch(userLoggedInAction());
            return res;
          } else {
            return false;
          }
        }),
        map(r => r)
      ).toPromise();
      if (result) {
        return true;
      }
    } catch (e: any) {
      alert(e?.message);
    }
    return false;
  }

  async loginAdmin(loginData: {
    email: string;
    password: string;
  }): Promise<any> {
    try {
      const { email, password } = loginData;
      // TODO: Misschien is er nog een betere manier om het op te halen?
      this.apollo.mutate(new LoginAdminMutation({ email, password })).subscribe((e: any) => {
        this.saveToken(e.data.loginAdmin.access_token);
        this.decodedToken = this.jwtHelperService.decodeToken(e.data.loginAdmin.access_token);
        this.router.navigate(['/manage/']);
        return true;
      });
    } catch (e) {
      // alert(e.message);
    }
    return false;
  }

  get authenticated$() {
    return this.authSubject.asObservable();
  }

  public async logout(type?: 'user' | 'admin'): Promise<boolean> {
    if (type === 'user') {
      this.router.navigate([environment.auth.error_redirect_user_url]);
    } else if (type === 'admin') {
      this.router.navigate([environment.auth.error_redirect_admin_url]);
    } else {
      this.router.navigateByUrl('/');
    }
    // this.cookieService.deleteAll();
    this.authSubject.next(false);
    this.actions$.dispatch(userLoggedOutAction());
    resetStores()
    // localStorage.removeItem(environment.auth.token_name);
    localStorage.clear();
    this.apollo.client.resetStore();
    this.decodedToken = null;
    // Sentry.setUser(null);
    // this.store.dispatch(new ClearCollections());
    return true;
  }

  get authenticated() {
    const token = localStorage.getItem(environment.auth.token_name);
    if (!token) {
      // token = this.cookieService.get(environment.auth.token_name);
    }
    return token;
  }

  get token() {
    return this.decodedToken;
  }

  saveToken(token: string) {
    localStorage.setItem(environment.auth.token_name, token);
    const date = new Date();
    date.setTime(date.getTime() + 365 * 24 * 60 * 60 * 1000);
    if (environment.production) {
      // this.cookieService.set(environment.auth.token_name, token, 1000, '/', environment.auth.cookie_domain, true, 'Lax');
    } else {
      // this.cookieService.set(environment.auth.token_name, token, 1000);
    }
  }
}
