import {
  Component,
  Input, OnChanges,
  OnInit
} from '@angular/core';
import { FileUploadService } from '../../services/file-upload.service';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-upload-input',
  templateUrl: './upload-input.component.html',
  styleUrls: ['./upload-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi:true,
      useExisting: UploadInputComponent
    }
  ]
})
export class UploadInputComponent implements ControlValueAccessor, OnInit, OnChanges {
  @Input() prefilled: string = '';
  files: File[] = [];
  fullUrl: string = '';

  touched = false;
  disabled = false;
  onChange = (fullUrl: any) => {};
  onTouched = () => {};

  constructor(private fileUploadService: FileUploadService,) {}

  ngOnInit(): void {
  }

  ngOnChanges() {
    if (this.prefilled?.length) {
      this.fullUrl = this.prefilled;
      this.preCreateFile(this.fullUrl)
    }
  }

  async preCreateFile(imageUrl: string){
    const response = await fetch(imageUrl);
    const data = await response.blob();
    const metadata = { type: 'image/jpeg' };
    const filename = imageUrl.split('/').pop();
    const file = new File([data], filename || imageUrl, metadata);
    this.files = [file];
  }

  async onSelect(e: any) {
    this.markAsTouched();
    if (!this.disabled) {
      this.files = e.addedFiles;
      const uploadedFile = await this.fileUploadService.uploadFile(this.files[0]);
      this.fullUrl = uploadedFile.fullUrl;
      this.onChange(this.fullUrl)
    }
  }

  writeValue(fullUrl: string) {
    this.fullUrl = fullUrl;
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }
}
